.menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    list-style: none;
    color: white;
    margin-right: 20px;
    margin-bottom: 0;
    margin-top: 0;
}

.header-links {
    display: flex;
    flex-direction: row;
    list-style: none;
    color: white;
    margin-right: 20px;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 5px;
}

.menu li {
    margin-left: 15px;

}

.header-links li {
    margin-left: 15px
}

.menu li a {
    font-family: 'Montserrat';
    font-weight: 100;
    font-size: 18px;
    text-decoration: none;
    color: white;
}

.header-links li a {
    font-family: 'Montserrat';
    font-weight: 100;
    font-size: 18px;
    text-decoration: none;
    color: white;
    display: flex;
    flex-direction: row;
}

.menu a:hover {
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
}

.header-links a:hover {
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
}

header {
    background-color: #2B3242;
    display: flex;
    justify-content: space-between;
    box-shadow: 5px 5px 15px 0px rgba(0,0,0,.5);
    position: relative;
    z-index: 1;
}
@media screen and (max-width: 1000px) {
    

    .opening h1 {
        font-size: 75px;
    }

    .opening h1 {
        margin-bottom: 5%;
    }

    .opening-icons {
        padding-top: 0;
    }


    .subtitle {
        display: flex;
        flex-flow: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;
    }

    .subtitle p {
        margin-bottom: 5px;
    }

    .email {
        border-bottom: 1px solid #000000;
        /* width: 30%; */
        padding-bottom: 15px;
        text-align: center;
    }

    

    .header-links p{
        display: none;
    }

    .menu {
        padding-right: 5px;
    }

    .menu p{
        font-size: 16px;
    }

}