@import './styles/header.css';
@import './styles/hero.css';
@import './styles/about.css';
@import './styles/footer.css';
@import './styles/project.css';
@import './styles/review.css';
@import './styles/viewProject.css';


@font-face {
    font-family: 'Bogart';
    src: url('./styles/transfonter.org-20230409-074459/BogartTrial-Regular.woff2') format('woff2'),
        url('./styles/transfonter.org-20230409-074459/BogartTrial-Regular.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

.opening, .about, .portfolio, .responsive-portfolio, .reviews{
    opacity: 0;
    transition: opacity 1s, transform 1s;
    transform: translateY(30px);
}

html {
    background-color: #F6F6F6;
    width: 100%;
    padding: 0;
    margin:0;
    }

body {
    width: 100%;
    margin: 0;
    padding: 0;
}

h1 {
    font-family: 'Bogart';
    font-size: 100px;
    font-weight: 500;
    line-height: 1;
    margin-top: 20px;
    text-align: center;
}

h2 {
    font-family: 'Montserrat';
    font-size: 70px;
    font-weight: 400;
}

h3 {
    color: white;
    font-family: 'Montserrat';
    font-size: 20px;
}

p {
    font-weight: 100;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
}

.links {
    list-style: none;
    display: flex;
    flex-flow: row;
}

.links a {
    display: flex;
    flex-flow: row;
}

ul img {
    padding-top: 12px;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    margin-left: 10px;
}

.links li {
    margin-left: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.links li:hover {
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
}
