@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
} 

.fadeInLeft {
  animation: fadeInLeft 2.0s ease;
}

.fadeOutRight {
  animation: fadeOutRight 1.0s ease;
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.fadeInRight {
  animation: fadeInRight 1.0s ease;
}

.fadeOutLeft {
  animation: fadeOutLeft 1.0s ease;
}

.reviews {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row;
    height: 500px;
    padding-bottom: 50px;
    padding-top: 50px;
    background-color: #e2e0e0b0;
   
    

}

.review {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row;
    width: 100%;
    margin-right: 30px;
    margin-left: 30px;

   
}

.review-content{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-flow: column;
}

.review img{
    width: 80%;
    margin-left: 40px;
}

.reviewImg-container{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  height: 30rem;
}

.review h2{
    font-size: 30px;
    margin: 10px 0 0 0;
}

.review h4 {
    font-family: 'Montserrat';
    font-style: italic;
      margin: 5px 0 0 0;
}

.review p {
    text-align: center;
}

.back-button, .forward-button{
  width: 100px;
  height: 90px;
  cursor: pointer;
  color: #b3b3b3b0;

  
}


.back-button:hover, 
.forward-button:hover{
  color: white;
}




@media screen and (max-width: 1300px){

  .review{
    flex-flow: column;
  }

 .reviews{
  height: fit-content;
 }

 .reviewImg-container{
    width: 15rem;
    height: 20rem;
}

  
}