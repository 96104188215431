.project-body{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    margin: 100px;
}

.project-body h3{
    color: black;
    font-family: 'Montserrat';
    text-align: center;
}

.project-body h1{
    font-size: 80px;
}

.project-body a:visited{
    color: black;
    font-weight: 600;
}

.project-body a {
    color: black;
    font-weight: 600;

}


.external-links {
    display: flex;
    justify-content: space-between;
    align-items: center ;
    flex-flow: row;
    width: 300px;
}

h3 a{
    color: black;
}

h3 a:visited{
    color: black
}

.main-image{
    width: 50%;
}

.video{
    width: 40%;
    margin-bottom: 20px;
    padding: 10px;

}

.img-para{
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
}

.mobile-img{
    width: 40%;
}

@media screen and (max-width: 750px) {
    
    .project-body{
        margin: 50px;
    }
    
    .project-body h1 {
        font-size: 60px;
    }

    .img-para {
        display: flex;
        flex-flow: column;
        justify-content: row;
        align-items: center;
    }

    .mobile-img {
        width: 80%;
    }

    .main-image {
        width: 100%;
    }

    .video {
        width: 100%
    }


}