.opening {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    height: 75%;
    background-color: #e2e0e0b0;
    
   
}

.phone-opening {
    display: none;
}

.opening h1{
    text-shadow: 5px 5px 15px 0px rgba(0,0,0,.5);
}

.opening p {
    text-transform: uppercase;
    font-family: 'Montserrat';
}

/* @keyframes scroll {
    0% { transform: translateY(0); }
    100% { transform: translateY(-50%); }
} */

.img-container{
    width: 50%;
    height: 650px;
    overflow: auto;
    /* animation: scroll 10s linear; */
}


.opening-img {
    width: 100%;
    height:max-content;
}

.opening-text {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}



.subtitle {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}

a:link {
    text-decoration: none;
    color: black;
}

a:visited {
    text-decoration: none;
    color: black;
}

.email:hover {
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 1px solid #000000;
    padding-bottom: 5px;
}

a:active,
a:hover,
a:visited,
a:link {
    text-decoration: none;
    border: none;
}

.opening-icons{
    display: flex;
    flex-direction: row;
    list-style: none;        
    color: black;
    
    margin-bottom: 0;
    margin-top: 0 ;
    margin-right: 10px;
    
    padding-top: 30px;
    padding-left: 0;
}

.opening-icons li svg{
    width: 35px;
    height: auto;
}



@media screen and (max-width: 950px) {
    .opening {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
        height: 75%;
        background-color: #e2e0e0b0;


    }

    .opening-text{
        margin-top: 40px;
    }

    .img-container {
        margin: 50px;
        height: 650px;
        
    }

    .projects-mobile{
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
    }

    .scroll-button {
        width: 10%;
        height: 10%;
        color: gray
    }

    .opening h1{
        font-size: 65px;
    }
}